import { branchNameMap } from "../../core/schema/Branch.js";
import type { Locale } from "../../core/schema/Locale.js";
import type { Order } from "../../core/schema/Order.js";
import type { OrderProduct } from "../../core/schema/OrderProduct.js";
import type { OrderVoucher } from "../../core/schema/OrderVoucher.js";
import { type PaymentType, paymentTypeNameMap } from "../../core/schema/PaymentType.js";
import { PriceLevel } from "../../core/schema/PriceLevel.js";
import type { ProductForCustomer } from "../../core/schema/Product.js";
import type { ProductSize } from "../../core/schema/ProductSize.js";
import type { VoucherProduct } from "../../core/schema/VoucherProduct.js";
import type { b2cDeliveryTypes } from "../../core/schema/b2cDeliveryTypes.js";
import { deliveryTypeToBranchMap } from "../../core/schema/deliveryTypeToBranchMap.js";
import { productSizeNameMap } from "../../core/schema/names/productSizeNameMap.js";
import { emptyOptions } from "../../core/schema/utils/emptyOptions.js";
import { getPriceOfOrder } from "../../core/schema/utils/getPriceOfOrder.js";
import { getPriceOfOrderProduct } from "../../core/schema/utils/getPriceOfOrderProduct.js";
import { transformProductToOrderProduct } from "../../core/schema/utils/transformProductToOrderProduct.js";
import { transformVoucherProductToOrderVoucher } from "../../core/schema/utils/transformVoucherProductToOrderVoucher.js";
import { roundToPlaces } from "../../core/utils/roundToPlaces.js";
import type { OrderAfterOverview } from "./stores/cart/Cart.js";

declare const gtag: (...args: unknown[]) => void;

interface DynamicRemarketingEvent {
	event: string;
	ecommerce?: Record<string, unknown>;
	[other: string]: unknown;
}

interface Item {
	item_name: string;
	item_id: string;
	item_variant?: string;
	price: number;
	quantity: number;
}

function getProductItem(orderProduct: OrderProduct, locale: Locale): Item {
	const price = getPriceOfOrderProduct({ ...orderProduct, quantity: 1 });

	return {
		item_name: orderProduct.product.name[locale],
		item_id: orderProduct.product.id,
		item_variant: productSizeNameMap[orderProduct.size][locale],
		price: roundToPlaces(price.withoutTax, 2),
		quantity: orderProduct.quantity,
	};
}

function getVoucherItem(voucher: OrderVoucher, locale: Locale, quantity?: number): Item {
	const price = roundToPlaces(voucher.orderVoucherProduct.value, 2);
	return {
		item_name: voucher.orderVoucherProduct.name[locale],
		item_id: voucher.orderVoucherProduct.id,
		price,
		quantity: quantity ?? voucher.quantity,
	};
}

export function reportProductDetail(product: ProductForCustomer, locale: Locale, size: ProductSize): void {
	const orderProduct = transformProductToOrderProduct(crypto.randomUUID(), product, 1, size, emptyOptions);
	const price = getPriceOfOrderProduct(orderProduct);

	const event: DynamicRemarketingEvent = {
		event: "view_item",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.withoutTax, 2),
			items: [getProductItem(orderProduct, locale)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportVoucherDetail(voucher: VoucherProduct, locale: Locale): void {
	const price = roundToPlaces(voucher.value, 2);
	const orderVoucher = transformVoucherProductToOrderVoucher("", voucher, 1);

	const event: DynamicRemarketingEvent = {
		event: "view_item",
		ecommerce: {
			currency: "CZK",
			value: price,
			items: [getVoucherItem(orderVoucher, locale, 1)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportAddedProductToCart(product: OrderProduct, locale: Locale, itemQuantity?: number): void {
	const productOfQuantity = { ...product, quantity: itemQuantity ?? product.quantity };
	const price = getPriceOfOrderProduct(productOfQuantity);
	const event: DynamicRemarketingEvent = {
		event: "add_to_cart",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.withoutTax, 2),
			items: [getProductItem(productOfQuantity, locale)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportAddedVoucherToCart(voucher: OrderVoucher, locale: Locale, itemQuantity?: number): void {
	const price = roundToPlaces(voucher.orderVoucherProduct.value, 2);
	const event: DynamicRemarketingEvent = {
		event: "add_to_cart",
		ecommerce: {
			currency: "CZK",
			value: price,
			items: [getVoucherItem(voucher, locale, itemQuantity ?? 1)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportRemovedProductFromCart(product: OrderProduct, locale: Locale, itemQuantity?: number): void {
	const quantity = itemQuantity ?? product.quantity;
	const productOfQuantity = { ...product, quantity };
	const price = getPriceOfOrderProduct(productOfQuantity);
	const event: DynamicRemarketingEvent = {
		event: "remove_from_cart",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.withoutTax, 2),
			items: [getProductItem(productOfQuantity, locale)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportRemovedVoucherFromCart(voucher: OrderVoucher, locale: Locale, itemQuantity?: number): void {
	const price = roundToPlaces(voucher.orderVoucherProduct.value, 2);

	const event: DynamicRemarketingEvent = {
		event: "remove_from_cart",
		ecommerce: {
			currency: "CZK",
			value: price,
			items: [getVoucherItem(voucher, locale, itemQuantity ?? 1)],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportCheckoutBegan(order: Pick<Order, "products" | "vouchers">, locale: Locale): void {
	const price = getPriceOfOrder(order, PriceLevel.Basic);
	const event: DynamicRemarketingEvent = {
		event: "begin_checkout",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.totalPrice.withoutTax, 2),
			items: [
				...Object.values(order.products).map((item) => getProductItem(item, locale)),
				...Object.values(order.vouchers).map((item) => getVoucherItem(item, locale)),
			],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function onPaymentTypeChanged(order: OrderAfterOverview, locale: Locale, paymentType: PaymentType): void {
	const price = getPriceOfOrder(order, PriceLevel.Basic);
	const event: DynamicRemarketingEvent = {
		event: "add_payment_info",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.totalPrice.withoutTax, 2),
			payment_type: paymentTypeNameMap[paymentType][locale],
			items: [
				...Object.values(order.products).map((item) => getProductItem(item, locale)),
				...Object.values(order.vouchers).map((item) => getVoucherItem(item, locale)),
			],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function onDeliveryTypeChanged(
	order: OrderAfterOverview,
	locale: Locale,
	deliveryType: (typeof b2cDeliveryTypes)[number],
): void {
	const price = getPriceOfOrder(order, PriceLevel.Basic);
	const event: DynamicRemarketingEvent = {
		event: "add_shipping_info",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(price.totalPrice.withoutTax, 2),
			shipping_tier: branchNameMap[deliveryTypeToBranchMap[deliveryType]],
			items: [
				...Object.values(order.products).map((item) => getProductItem(item, locale)),
				...Object.values(order.vouchers).map((item) => getVoucherItem(item, locale)),
			],
		},
	};

	gtag("event", event.event, event.ecommerce);
}

export function reportPurchase(order: Order, locale: Locale): void {
	const priceWithTax = order.price.totalPrice.withTax;
	const priceWithoutTax = order.price.totalPrice.withoutTax;
	const event: DynamicRemarketingEvent = {
		event: "purchase",
		ecommerce: {
			currency: "CZK",
			value: roundToPlaces(priceWithoutTax, 2),
			tax: roundToPlaces(priceWithTax - priceWithoutTax, 2),
			shipping: roundToPlaces(order.price.deliveryPrice, 2),
			transaction_id: order.goPayData.id ?? "",
			items: [
				...Object.values(order.products).map((item) => getProductItem(item, locale)),
				...Object.values(order.vouchers).map((item) => getVoucherItem(item, locale)),
			],
		},
	};

	gtag("event", event.event, event.ecommerce);
}
